body {
  background-color: #282c34;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.app-header {
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-top: 3rem;
}

.app-body {
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
  margin-top: 4rem;
}

.anchor-link {
  color: #aee9f0;
}

.carousel-caption {
  position: relative;
  left: auto;
  right: auto;
}

.app-carrot {
  max-height: 25%;
  min-width: 60vw;
}

.carrot-img {
  min-width: 40vw;
  max-width: 50vw;
  min-height: 20vw;
  max-height: 40vw;
}

.carousel-inner {
  padding-inline: 4rem;
}

.w-80 {
  width: 80%;
  margin-inline: 10%;
}